.header {
  margin-bottom: 12px !important;
  background-color: #121518 !important;
  padding: 9px !important;
  border-radius: 6px !important;
}

.header .header_link {
  font-weight: 500;
  color: #ffffff !important;
}

.header .header_link:hover {
  color: #f7a5de !important;
  box-shadow: 3px 6px 2px rgb(211, 206, 208);
  border-radius: 7px;
}

.header .header_link_active {
  font-weight: 500;
  color: #f7a5de !important;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: white !important;
  border: 2px solid rgb(22, 189, 245) !important;
  transition: box-shadow 0.15s ease-in-out;
}

@media (min-width: 768.98px) {
  .appointment-btn {
    display: none;
  }
}

@media (max-width: 768.98px) {
  .header-right {
    display: none;
  }
  .navbar-nav .nav-link {
    border-bottom: 0.1px solid !important;
  }
}
