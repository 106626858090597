.footer-box {
  position: relative;
  margin-top: 45px;
  background: #121518;
  color: #ffffff;
  border-radius: 0px 20px 0px 20px;
}

.material-design-icon {
  font-size: 24px;
  margin-right: 5px;
}

.useful-links:hover {
  color: #f7a5de;
  text-decoration: none;
}

.contact {
  text-decoration: none;
  color: #ffffff;
}

.contact:hover {
  color: #96c9fb;
}
