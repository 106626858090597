.about-section {
  padding: 40px;
}
.about-section .about-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 8px 12px -2px #000000;
}

.about-section-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.about-section-btn {
  display: flex;
  justify-content: flex-end;
}

.about-section .about-text p {
  font-size: 16px;
  margin: 5px 20px;
  text-align: start;
}

.about-section .hr {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #001aff;
  margin-bottom: 10px;
}

hr:not([size]) {
  height: 10px !important;
}

.keyClients {
  position: absolute;
  top: 460px;
  left: 1217px;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 1024px) {
  .keyClients {
    display: none;
  }
}
