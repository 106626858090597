.top-bar {
  max-height: 95px;
  width: auto;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.top-bar .logo img {
  width: 102px;
  height: 102px;
}

.top-bar .logo img:hover {
  width: 103px;
  height: 103px;
}

#registerTip {
  color: rgb(235, 235, 108);
  font-size: 17.6px;
  text-decoration: none;
}

.social-svg {
  width: 50px;
  height: 50px;
  padding: 0 10px 0 10px;
}

/* .social-svg:hover {
  width: 85px;
  height: 85px;
} */

.top-bar .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90px;
  padding: 0 20px;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.top-bar .social {
  display: flex;
  height: 90px;
  font-size: 0;
}

.top-bar .social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  font-size: 22px;
  color: #ffffff;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.top-bar .social a:hover {
  width: 85px;
  height: 85px;
}

.top-bar .social a:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}

@media (min-width: 992px) {
  .top-bar {
    padding: 0px 100px 0px 0px;
  }
}

@media (max-width: 991.98px) {
  .top-bar .logo {
    text-align: center;
  }

  .top-bar .social {
    display: none;
  }
  .top-bar .text {
    display: none;
  }
}

@media (max-width: 1024px) {
  .top-bar {
    min-height: 140px;
  }
}
