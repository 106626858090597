.carousel-item {
  min-height: 300px;
}

.carousel-caption h5 {
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 25px;
  font-weight: 900;
  position: absolute;
  top: -300px;
  left: 100px;
}

.carousel-caption p {
  font-size: 20px;
  margin: auto;
  width: 100%;
  line-height: 1.5;
  position: absolute;
  top: -200px;
  left: 100px;
}

.custom-btn {
  text-decoration: none;
}

@media (max-width: 768px) {
  .w-100 {
    height: 60vh;
  }

  .carousel-caption h5 {
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 25px;
    font-weight: 900;
    position: absolute;
    top: -350px;
    left: 100px;
  }

  .carousel-caption p {
    font-size: 20px;
    margin: auto;
    width: 100%;
    line-height: 1.5;
    position: absolute;
    top: -200px;
    left: 100px;
  }
  .custom-btn {
    position: absolute;
    top: -200px;
    left: 50px;
    margin-top: 30px;
  }
}

@media (max-width: 414px) {
  .w-100 {
    height: 50vh;
  }
  .carousel-caption h5 {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 25px;
    font-weight: 900;
    display: flex;
    text-align: center;
    position: absolute;
    top: -215px;
    left: -20px;
    margin-top: 50px;
  }

  .carousel-caption p {
    display: none;
  }
  .custom-btn {
    position: absolute;
    top: -200px;
    left: 50px;
    margin-top: 70px;
  }
}

@media (min-width: 415px) {
  .custom-btn {
    position: absolute;
    top: -100px;
    left: 400px;
  }
}
