.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form > h1 {
  margin-bottom: 30px;
}

.form > input,
textarea {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
}

.form > input:focus,
textarea:focus {
  border: 1px solid rgb(0, 0, 196);
}

.form > textarea {
  height: 150px;
  max-width: 500px;
  min-height: 100px;
}

.form > label {
  padding: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
}

.form > button {
  padding: 20px;
  border: none;
  background-color: rgb(2, 2, 110);
  font-weight: 500;
  font-size: 20px;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.form > button:hover {
  background-color: rgb(0, 0, 196);
}

@media (max-width: 768px) {
  .contact-img {
    display: none;
  }
}
