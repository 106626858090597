.custom_btn {
  background-color: var(--btn-color) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 50px !important;
  padding: 1px 12px !important;
  line-height: 35px !important;
  min-width: 100px !important;
  outline: none !important;
  color: #ffffff;
  border: 2px solid #8acef2 !important;
}

.custom_btn:hover {
  background-color: #000000 !important;
  box-shadow: 3px 6px 2px rgb(211, 206, 208);
}

.custom_btn .btn_icon_container {
  color: #000000 !important;
  background: #e9e0e6 !important;
  border-radius: 50px !important;
  height: 27px !important;
  width: 27px !important;
  line-height: 29px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.custom_btn .btn_icon_container > .MuiSvgIcon-root {
  font-size: 16px !important;
}

.custom_btn .btn_text {
  font-size: 14px !important;
  text-transform: none !important;
  text-align: center;
  width: 100%;
  margin-right: 5px;
  margin-left: 5px;
  color: #ffffff;
}
