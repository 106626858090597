.team {
  position: relative;
  padding: 45px 0 15px 0;
  margin: 0px 30px 30px 30px;
}

.team .team-item {
  margin-bottom: 30px;
}

.team .team-img {
  position: relative;
}

.team .team-img img {
  width: 100%;
}

.team .team-text {
  position: relative;
  padding: 50px 15px 30px 15px;
  text-align: center;
  background: #121518;
}

.team .team-text h2 {
  font-size: 22px;
  font-weight: 600;
  color: #eeeeee;
}

.team .team-text p {
  margin: 0;
  color: #d4d4d4;
}

.team .team-social {
  position: absolute;
  width: calc(100% - 60px);
  height: 50px;
  top: -25px;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #909090;
  font-size: 0;
  transition: 0.5s;
}

.team .team-item:hover .team-social {
  width: 100%;
  left: 0;
}
