.feature-top {
  position: relative;
  background: #f0efef;
  color: #000;
  margin-bottom: 20px;
}

.feature-top .col-md-3 {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 1px 3px rgb(10, 10, 10);
  border-radius: 50%;
}
.feature-top .col-md-3:hover {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 6px 7px rgb(10, 10, 10);
  border-radius: 50%;
}

@media (max-width: 575.98px) {
  .feature-top .col-md-3:nth-child(1n) {
    border-right: none;
  }

  .feature-top .col-md-3:last-child {
    border-bottom: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .feature-top .col-md-3:nth-child(2n) {
    border-right: none;
  }

  .feature-top .col-md-3:nth-child(3n),
  .feature-top .col-md-3:nth-child(4n) {
    border-bottom: none;
  }
}

@media (min-width: 768px) {
  .feature-top .col-md-3 {
    border-bottom: none;
  }

  .feature-top .col-md-3:nth-child(4n) {
    border-right: none;
  }
}

.feature-top .feature-item {
  padding: 30px 0;
  text-align: center;
}

.feature-top .feature-item .material-icons {
  color: #800080;
  font-size: 35px;
  margin-bottom: 10px;
}

.feature-top .feature-item h3 {
  color: #080808;
  font-size: 30px;
  font-weight: 600;
}

.feature-top .feature-item p {
  color: #0a0a0a;
  margin: 0;
  font-size: 18px;
}
