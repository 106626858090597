* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("../src/assets/images/background.jpg");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "EB Garamond", serif !important;
  font-weight: 600 !important;
  font-style: italic !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.boxshadow {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

nav.MuiTypography-root.MuiBreadcrumbs-root.MuiTypography-body1.MuiTypography-colorTextSecondary {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.hr {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #001aff;
  margin-bottom: 10px;
}

.card-boxshadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
}

.section-header h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

hr:not([size]) {
  height: 10px !important;
  background-color: #dbb4b4;
}
