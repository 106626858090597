.service-section-header h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.service {
  margin: 0px 20px 20px 20px;
}

.overflow {
  overflow: hidden;
}

.card-img {
  padding: 8px;
  transform: scale(1);
}

.card-img:hover {
  transform: scale(1.02);
}
