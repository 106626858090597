:root {
  --btn-color: #3f4246;
}

/* remove padding on sm device */
@media (min-width: 350px) {
  div .MuiContainer-root {
    padding-left: 0px;
    padding-right: 0px;
  }
}
