.faqs-section {
  padding: 15px;
}
.faqs-section .faqs-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 8px 12px -2px #000000;
}

.faqs-section-header {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 15px !important;
}

.faqs-section-btn {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.faqs-text span {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  text-align: center;
  background: #121518;
  color: #df27b1;
  font-weight: 700;
  border-radius: 50%;
}
