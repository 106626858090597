.timeline {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  background: url("../../assets/images/timeline-background.gif");
}

.timeline .timeline-container {
  padding: 0 15px;
  position: relative;
  background: inherit;
  width: 50%;
  margin-top: -5px;
}

.timeline .timeline-container.left {
  left: 0;
}

.timeline .timeline-container.right {
  left: 50%;
}

.timeline .timeline-container::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 5px;
  background: #f7a5de;
  z-index: 1;
}

.timeline .timeline-container.left::before {
  top: 0;
  right: 0;
}

.timeline .timeline-container.right::before {
  bottom: 0;
  left: 0;
}

.timeline .timeline-container::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 5px;
  background: #f7a5de;
  z-index: 1;
}

.timeline .timeline-container.left::after {
  bottom: 0;
  right: 0;
}

.timeline .timeline-container.right::after {
  top: 0;
  left: 0;
}

.timeline .timeline-container:first-child::before,
.timeline .timeline-container:last-child::before {
  display: none;
}

.timeline .timeline-container .timeline-content {
  padding: 30px;
  background: #2a2829;
  position: relative;
  border-right: 5px solid #f7a5de;
  border-radius: 11px;
}

.timeline .timeline-container.right .timeline-content {
  border-left: 5px solid #f7a5de;
}

.timeline .timeline-container .timeline-content h2 {
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 600;
  color: #fbf3ef;
}

.timeline .timeline-container .timeline-content h2 span {
  display: inline-block;
  margin-right: 15px;
  padding: 5px 10px;
  color: #000000;
  background: #cecbcc;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  border-radius: 25px;
}

.timeline .timeline-container .timeline-content p {
  margin: 0;
  font-size: 16px;
  color: #bebebe;
}

@media (max-width: 767.98px) {
  .timeline .timeline-container {
    width: 100%;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
  }

  .timeline .timeline-container:last-child {
    padding-bottom: 0;
  }

  .timeline .timeline-container.right {
    left: 0%;
  }

  .timeline .timeline-container.left::after,
  .timeline .timeline-container.right::after,
  .timeline .timeline-container.left::before,
  .timeline .timeline-container.right::before {
    width: 5px;
    height: 35px;
    left: 15px;
  }

  .timeline .timeline-container.left .timeline-content,
  .timeline .timeline-container.right .timeline-content {
    border-left: 5px solid #f7a5de;
    border-right: none;
  }
}
