.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-color {
  color: #fbf3ef;
}

.desc-color {
  color: #f3efef;
}

.Cardfooter-color {
  color: #000000;
}

.customTabs_item {
  color: #ffffff;
  background-color: #000000;
}

.customTabs_item:hover {
  color: #ffffff;
  background-color: #000000;
}

.active-tab {
  color: #ffffff !important;
  background-color: #000000 !important;
}

.cardText-date {
  position: absolute;
  top: 16.5rem;
  left: 17rem;
}

.MuiTab-root {
  border: 2px solid black !important;
  margin: 0px 6px !important;
  border-radius: 0px 60px 0px 60px !important;
}

.portfolio-wrap:hover {
  margin-top: -10px;
}

.caption-wrap {
  background: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.1) 100%
  );
}

@media (max-width: 768px) {
  .tabs {
    display: none;
  }

  .cardText-date {
    display: none;
  }
}

@media (max-width: 1024px) {
  .cardText-date {
    position: absolute;
    top: 20.5rem;
    left: 21rem;
  }
}
