.clientSection {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background: url("../../assets/images/clients.jpg");
  margin: 15px;
}

@media (max-width: 768px) {
  .MuiTimelineItem-missingOppositeContent:before {
    flex: 1;
    content: none !important;
    padding: 6 px 16 px;
  }
}

.MuiTimelineContent-root {
  flex: 1;
  padding: 8px 16px !important;
  color: #210070;
  font-size: 19px;
}
